<template>
  <div class="page-card-demo  page-info-content">
    <el-table :border="true" :data="prodBaseinfoHisList" class="br-table" style="width: 80%">
      <el-table-column label="序号" type="index" width="50" />
      <el-table-column label="产品编号" prop="proId" />
      <el-table-column label="修改时间" prop="modifyTime" />
      <el-table-column label="修改人" prop="modifyUser" />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" type="success" class="table-detail-button" @click="handleDetail(scope.row)">
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="详情" :visible.sync="dialogVisible" width="890px" class="br-dialog">
      <productInformation :detail-data="detailData" :pro-info="proInfo" :compacts="compactList" />
    </el-dialog>
  </div>
</template>
<script>
import ProductInformation from './productInformation.vue'
import { productDetail, prodBaseinfoHis } from '@/api/tradeFinancing.js'
export default {
  components: {
    ProductInformation
  },
  data() {
    return {
      prodBaseinfoHisList: [],
      dialogVisible: false,
      detailData: [],
      proInfo: {},
      compactList: []

    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      prodBaseinfoHis({ proId: this.$route.query.proId }, res => {
        if (res.code === 200) {
          this.prodBaseinfoHisList = res.data
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }, err => {
        this.$message({
          type: 'error',
          message: err
        })
      })
    },
    handleDetail(row) {
      productDetail({ id: row.id }, res => {
        if (res.code === 200) {
          this.dialogVisible = true
          this.detailData = res.data.flist
          this.proInfo = res.data.clist[0]
          this.compactList = res.data.compactList
        }
      })
    }
  }
}
</script>
