<template>
  <div class="page-card-demo  page-info-content">
    <img :src="`${scfURL}/task/getDiagram?activiteId=${this.$route.query.activiteId}&currentNodeId=${this.$route.query.currentNodeId}`">
  </div>
</template>
<script>
import { scfURL } from '@/systemConfiguration/index'
export default {
  data() {
    return {
      scfURL
    }
  }
}
</script>
