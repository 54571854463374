<template>
  <div class="page-card-demo  page-info-content">
    <el-tabs>
      <el-tab-pane label="产品信息">
        <productInformation :detail-data="detailData" :pro-info="proInfo" :compacts="compactList" />
      </el-tab-pane>
      <el-tab-pane v-if="$route.query.currentNodeId" label="流程监控">
        <processMonitor />
      </el-tab-pane>
      <el-tab-pane label="审核历史">
        <auditHistory />
      </el-tab-pane>
      <el-tab-pane v-if="$route.query.type=='2'" label="审核意见">
        <auditSuggestion />
      </el-tab-pane>
      <el-tab-pane label="修改记录">
        <modifyRecord />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import ProductInformation from './productInformation.vue'
import ProcessMonitor from './processMonitor.vue'
import AuditHistory from './auditHistory.vue'
import ModifyRecord from './modifyRecord.vue'
import AuditSuggestion from './auditSuggestion.vue'
import { productDetail } from '@/api/tradeFinancing.js'
export default {
  components: { ProductInformation, AuditHistory, ProcessMonitor, ModifyRecord, AuditSuggestion },
  data() {
    return {
      detailData: [],
      proInfo: {},
      compactList: []
    }
  },
  created() {
    const id = this.$route.query.id || ''
    const proId = this.$route.query.proId || ''
    const params = id ? { id } : { proId }
    // 额度管理-核心企业额度详情页-产品专项额度会进来详情，这里穿的是proId，从产品列表进来的详情是id
    productDetail(params, res => {
      if (res.code === 200) {
        this.detailData = res.data.flist
        this.proInfo = res.data.clist[0]
        this.compactList = res.data.compactList
      }
    })
  }
}
</script>
<style lang="scss">
  .template-container {
    background-color: #fff;
    border-radius: 4px;
    padding: 10px 30px 14px;
}
</style>
